import React from "react"
import { Layout } from "../components/layout"
import Button from "../components/button"
import styles from "../styles/style"
import PalmerArmsFood from "../images/partners-and-stockist-v3.jpg"

const BecomeAPartner = () => {
  return (
    <Layout>
      <div className={` ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <section className="body-font ">
            <div className="container  mx-auto mb-6">
              <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full mb-2">
                <span className="text-green-gradient">Become a Partner</span>{" "}
                <br className="xx:block hidden" />{" "}
                <span className="text-green-gradient">or Stockists</span>{" "}
              </h2>
              <p className="mb-4 leading-relaxed">
                Welcome to our become a partners and stockist section. We would
                like to start with our very first partner and stockist and show
                our appreciation on our partnership, that we hope will benefit
                all in the community.
              </p>
              <p className="mb-4 leading-relaxed">
                If you are interested in becoming a partner or stockist, or you
                want more information about what a partnership or being a
                stockist means for you, then please get in touch using the form
                provided below.
              </p>
            </div>
          </section>
          <section className="text-gray-700 body-font mt-6 mb-6 border-t border-gray-200">
            <div className="container mx-auto flex  md:flex-row flex-col items-center mt-8">
              <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-8 md:mb-0 items-center ">
                <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full mb-2">
                  <span className="text-green-gradient">Premier Partner</span>
                </h2>
                <p className="mb-4 leading-relaxed">
                  The Palmer Arms was our first partnership thanks a receptive
                  landlord doing all he can helping small business and start ups
                  in the local area.
                </p>
                <p className="mb-4 leading-relaxed">
                  In fact, I would like to point out that through Chrys’s
                  intervention, he also gave us our first offical order, that
                  allowed us to setup our business accounts.
                </p>
                <p className="mb-4 leading-relaxed">
                  Hopefully this is just the start of one of many beautiful
                  relationships where we can be more than just stockists and
                  collaborate on summer initiatives and events for our community
                </p>
                <div className="flex justify-center ">
                  <a
                    href="https://thepalmerarms.com/"
                    alt="The palmer arms pub in dorney website"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <Button
                      label="The Palmer Arms"
                      //styles={`mt-6`}
                    />
                  </a>
                </div>
              </div>
              <div className="lg:max-w-lg lg:w-full md:w-1/2 ">
                <img
                  className="object-cover object-center rounded"
                  alt="hero"
                  src={PalmerArmsFood}
                  //src="https://dummyimage.com/720x600/edf2f7/a5afbd"
                />
              </div>
            </div>
          </section>

          <section className="text-gray-600 body-font relative container mx-auto mb-10">
            <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full mb-2">
              <span className="text-green-gradient">Join Us Today</span>
            </h2>
            <div className="flex sm:flex-nowrap flex-wrap">
              <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                <iframe
                  width="100%"
                  height="100%"
                  className="absolute inset-0"
                  frameBorder="0"
                  title="map"
                  marginHeight="0"
                  marginWidth="0"
                  scrolling="no"
                  src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=%C4%B0zmir+(My%20Business%20Name)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
                  style={{ filter: "grayscale(1) contrast(1.2) opacity(0.4)" }}
                ></iframe>
                <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                  <div className="lg:w-1/2 px-6">
                    <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                      ADDRESS
                    </h2>
                    <p className="mt-1">
                      H&S Pepper Co Taplow, Windsor, You can use the email link
                      of form to contact us
                    </p>
                  </div>
                  <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                    <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                      EMAIL
                    </h2>
                    <span className="text-green-500 leading-relaxed">
                      click{" "}
                      <a
                        href="/"
                        alt="click here to email us about becoming a partner or stockist"
                      >
                        here
                      </a>{" "}
                      to email us today
                    </span>
                    <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                      PHONE
                    </h2>
                    <p className="leading-relaxed">123-456-7890</p>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full mt-8 sm:mt-0">
                <h2 className="text-green-gradient text-lg mb-1 font-medium title-font">
                  Partnerships & Stockists
                </h2>
                <p className="leading-relaxed mb-5 text-gray-600">
                  Please fill out the form below to initiate the process of
                  becoming a partner or stockist for H&S Pepper Co. Or consider
                  callaborating with us on initiatives and key events we work on
                  throughout the year.
                </p>
                <div className="relative mb-4">
                  <label
                    htmlFor="name"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="w-full bg-white rounded border border-gray-300 focus:border-green-500 focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
                <div className="relative mb-4">
                  <label
                    htmlFor="email"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="w-full bg-white rounded border border-gray-300 focus:border-green-500 focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
                <div className="relative mb-4">
                  <label
                    htmlFor="message"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    className="w-full bg-white rounded border border-gray-300 focus:border-green-500 focus:ring-2 focus:ring-green-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  ></textarea>
                </div>
                <button className="text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg">
                  Button
                </button>
                <p className="text-xs text-green-gradient semi-bold mt-3 text-center">
                  H&S Pepper Co, all rights reserved
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default BecomeAPartner
